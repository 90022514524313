<template>
  <div class="component-settings-subscription-payment-methods px-4">
    <b-row>
      <b-col>
        <h3 class="pt-4">{{$t(translationPath + 'title')}}</h3>
        <div v-html="$t(translationPath + 'description')"></div>

        <!-- TABLE -->
        <b-table class="spirecta-simple-table settings-invoice-table mb-0" show-empty responsive striped
                 :items="paymentMethods"
                 :fields="tableColumns"
                 :busy="!isLoaded"
                 :emptyText="$t(translationPath + 'no_data')"
        >
          <template v-slot:table-busy>
            <loader/>
          </template>

          <template v-slot:cell(brand)="row">
            {{ row.value }}
            <b-badge v-if="row.item.is_default" variant="info">{{ $t(translationPath + 'badge_default') }}</b-badge>
          </template>

          <template v-slot:cell(last4)="row">
            **** **** **** {{ row.value }}
          </template>

          <template v-slot:cell(expired_at)="row">
            {{ row.item.exp_month.toString().padStart(2, '0') }} / {{ row.item.exp_year }}
          </template>

          <template v-slot:cell(actions)="row">
            <b-button
              v-if="!row.item.is_default"
              @click="onSetDefaultClick(row.item)"
              :title="$t(translationPath + 'btn_set_default')"
              class="btn plain-btn text-regular action-button mr-2"
            >
              <i class="flaticon solid star-2 text-info"></i> {{ $t( translationPath + 'make_primary') }}</b-button>
            <b-button
              v-if="row.item.is_default === 0"
              @click="onDeletePaymentMethod(row.item)"
              :title="$t('common.delete')"
              class="btn plain-btn text-regular action-button"
            >
              <i class="flaticon solid trash-3 text-danger"></i> {{ $t('common.delete') }}
            </b-button>
          </template>
        </b-table>

        <!-- ADD BUTTON FORM -->
        <b-row no-gutters v-if="subscriptionStatus && !subscriptionStatus.on_generic_trial" class="mt-3">
          <b-col>
            <b-link href="#" class="btn btn-sm btn-outline-secondary mb-2" @click.prevent="onAddPaymentMethod">{{ $t(translationPath + 'btn_show_add_payment_method_form') }}</b-link>
          </b-col>
        </b-row>

        <!-- ADD PAYMENT METHOD FORM -->
        <div v-if="bIsAddPaymentMethodFormShown">
          <b-row no-gutters>
            <b-col cols="12">
              <h3 class="pt-5 pb-0 mb-0">{{ $t( translationPath + 'add_payment_method_form.title') }}</h3>
              <p class="pb-0 mb-0">{{ $t( translationPath + 'add_payment_method_form.description') }}</p>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col cols="12">
              <div id="card-element2" class="form-control rounded-pill my-2 pt-2" :class="stripeCardElementExtraClass"></div>
              <div class="invalid-feedback">{{ stripeValidationError }}</div>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col cols="12">
              <b-link href="#" class="btn btn-sm btn-primary" @click.prevent="doAddPaymentMethod" :data-secret="stripeSetupIntent ? stripeSetupIntent.client_secret : ''">{{ $t(translationPath + 'add_payment_method_form.btn_add') }}</b-link>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col cols="12" class="mt-4">
              <hr />
            </b-col>
          </b-row>
        </div>

      </b-col>
    </b-row>

    <delete-modal
      ref="DeletePaymentMethodModal"
      :heading="$t(translationPath + 'delete_pm_modal_heading')"
      :message="$t(translationPath + 'delete_pm_modal_message')"
      @on-delete-cancel="paymentMethodToDelete = null"
      @on-delete-confirm="doDeletePaymentMethod"
    />

    <info-modal
      ref="DeleteDefaultRestrictModal"
      variant="warning"
      :title="$t(translationPath + 'delete_pm_restrict_modal_heading')"
      :message="$t(translationPath + 'delete_pm_restrict_modal_message')"
    />
  </div>
</template>

<script>
import axios from 'axios'
import stripeMixin from './common/stripeMixin'
import Loader from '@/components/common/Loader'
import DeleteModal from '@/components/modals/DeleteModal'
import InfoModal from '@/components/modals/InfoModal'
import { mapState } from 'vuex'

export default {
  name: 'SubscriptionPaymentMethods',
  components: { Loader, DeleteModal, InfoModal },
  mixins: [stripeMixin],
  props: ['subscription-status'],
  data () {
    return {
      isLoaded: false,
      translationPath: 'settings.subscription-v2.payment_methods.',
      paymentMethods: [],
      bIsAddPaymentMethodFormShown: false,
      bIsStripeRequestInProcess: false,
      stripeCardElementExtraClass: '',
      stripeValidationError: '',
      paymentMethodToDelete: null
    }
  },
  computed: {
    ...mapState('user', ['currentUser']),
    tableColumns () {
      return [
        { key: 'brand', label: this.$t(this.translationPath + 'brand') },
        { key: 'last4', label: this.$t(this.translationPath + 'last4') },
        { key: 'expired_at', label: this.$t(this.translationPath + 'expired_at') },
        { key: 'actions', label: '', class: 'td-actions text-right' }
      ]
    }
  },
  methods: {
    loadData () {
      this.isLoaded = false
      Promise.all([
        this.loadPaymentMethods(),
        this.includeStripe()
      ])
        .catch(err => {
          console.error(err)
        })
        .then(() => {
          this.isLoaded = true
        })
    },
    async loadPaymentMethods () {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_ROOT_API}/payment-methods-v2`)
          .then(response => {
            this.paymentMethods = response.data.data
            resolve(response.data.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    onAddPaymentMethod () {
      this.bIsAddPaymentMethodFormShown = !this.bIsAddPaymentMethodFormShown
      if (this.bIsAddPaymentMethodFormShown) {
        this.loadStripeSetupIntent()
          .then(() => {
            this.initCardElement()
          })
      }
    },
    async doAddPaymentMethod () {
      if (this.bIsStripeRequestInProcess) { // Prevent multirequest to Stripe from user clicks on button.
        return false
      }

      this.bIsStripeRequestInProcess = true
      const { setupIntent, error } = await this.stripe.stripe.confirmCardSetup(
        this.stripeSetupIntent.client_secret, {
          payment_method: {
            card: this.stripe.cardElement,
            billing_details: { name: this.currentUser.firstname + ' ' + this.currentUser.lastname }
          }
        }
      )

      if (error) {
        this.bIsStripeRequestInProcess = false
        if (error.type === 'validation_error') {
          this.stripeCardElementExtraClass = 'is-invalid'
          this.stripeValidationError = error.message
        } else {
          console.error(error)
        }
      } else {
        this.stripeSetupIntent = null
        this.bIsStripeRequestInProcess = true
        this.isLoaded = false
        this.stripe.cardElement.clear()
        this.savePaymentMethod(setupIntent.payment_method)
      }
    },
    async savePaymentMethod (paymentMethod) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_ROOT_API}/payment-methods-v2`, { payment_method: paymentMethod })
          .then(response => {
            this.bIsAddPaymentMethodFormShown = false
            this.loadPaymentMethods()
              .catch(err => {
                console.error(err)
              })
              .then(() => {
                this.isLoaded = true
                this.bIsStripeRequestInProcess = false
              })
            this.$bvToast.toast(this.$t(this.translationPath + 'add_payment_method_form.toast.add_payment_method_success'), {
              title: this.$t(this.translationPath + 'add_payment_method_form.toast.add_payment_method_title'),
              autoHideDelay: 3000,
              variant: 'success'
            })
          })
          .catch(err => {
            console.error(err)
            this.$bvToast.toast(this.$t(this.translationPath + 'add_payment_method_form.toast.add_payment_method_fail'), {
              title: this.$t(this.translationPath + 'add_payment_method_form.toast.add_payment_method_title'),
              autoHideDelay: 3000,
              variant: 'danger'
            })
          })
      })
    },
    onSetDefaultClick (item) {
      if (this.bIsStripeRequestInProcess) {
        return false
      }

      this.bIsStripeRequestInProcess = true

      this.$bvToast.toast(this.$t(this.translationPath + 'toast.set_default_wait_info'), {
        title: this.$t(this.translationPath + 'toast.set_default_title'),
        autoHideDelay: 3000,
        variant: 'info'
      })

      axios.put(`${process.env.VUE_APP_ROOT_API}/payment-methods-v2/set-default-payment-method`, { payment_method: item.stripe_payment_method_id })
        .then(() => {
          this.loadPaymentMethods()
            .catch(err => {
              console.error(err)
              this.$bvToast.toast(this.$t(this.translationPath + 'toast.set_default_fail'), {
                title: this.$t(this.translationPath + 'toast.set_default_title'),
                autoHideDelay: 3000,
                variant: 'danger'
              })
            })
            .then(() => {
              this.bIsStripeRequestInProcess = false
              this.$bvToast.toast(this.$t(this.translationPath + 'toast.set_default_success'), {
                title: this.$t(this.translationPath + 'toast.set_default_title'),
                autoHideDelay: 3000,
                variant: 'success'
              })
            })
        })
        .catch(err => {
          console.error(err)
          this.bIsStripeRequestInProcess = false
          this.$bvToast.toast(this.$t(this.translationPath + 'toast.set_default_fail'), {
            title: this.$t(this.translationPath + 'toast.set_default_title'),
            autoHideDelay: 3000,
            variant: 'danger'
          })
        })
    },
    onDeletePaymentMethod (item) {
      if (item.is_default) {
        this.$refs.DeleteDefaultRestrictModal.show()
        return false
      }

      this.paymentMethodToDelete = item.stripe_payment_method_id
      this.$refs.DeletePaymentMethodModal.show()
    },
    doDeletePaymentMethod () {
      if (!this.isLoaded) {
        return false
      }

      this.isLoaded = false

      const deleteData = {
        payment_method: this.paymentMethodToDelete
      }

      axios.post(`${process.env.VUE_APP_ROOT_API}/payment-methods-v2/delete-by-stripe-pm-id`, deleteData)
        .then(response => {
          this.loadPaymentMethods()
            .catch(err => {
              console.error(err)
              this.$bvToast.toast(this.$t(this.translationPath + 'toast.delete_fail'), {
                title: this.$t(this.translationPath + 'toast.delete_title'),
                autoHideDelay: 3000,
                variant: 'danger'
              })
            })
            .then(() => {
              this.isLoaded = true
              this.$bvToast.toast(this.$t(this.translationPath + 'toast.delete_success'), {
                title: this.$t(this.translationPath + 'toast.delete_title'),
                autoHideDelay: 3000,
                variant: 'success'
              })
            })
        })
        .catch(err => {
          console.error(err)
          this.isLoaded = true
          this.$bvToast.toast(this.$t(this.translationPath + 'toast.delete_fail'), {
            title: this.$t(this.translationPath + 'toast.delete_title'),
            autoHideDelay: 3000,
            variant: 'danger'
          })
        })
    }
  },
  created () {
    this.loadData()
  }
}
</script>

<style lang="scss">
.component-settings-subscription-payment-methods {
  table {
    td, th{
      font-size: 90%;
    }
    .action-button {
      padding: 0;
      border: none;
      line-height: normal;
      i {
        margin: 0;
        font-size: 13px;
      }
      &:hover {
        background: transparent;
      }
      &:active {
        background-color: transparent !important;
        border-color: transparent !important;
      }
      &:focus {
        background: transparent;
        box-shadow: none !important;
      }
    }
  }
}
</style>
