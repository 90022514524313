import axios from 'axios'

export default {
  data () {
    return {
      stripeSetupIntent: null,
      stripe: {
        stripe: null,
        elements: null,
        cardElement: null
      },
      stripeCardElementExtraClass: '',
      stripeValidationError: ''
    }
  },
  methods: {
    async loadStripeSetupIntent (bForce = false) {
      return new Promise((resolve, reject) => {
        if (this.stripeSetupIntent && !bForce) {
          resolve(this.stripeSetupIntent)
        } else {
          axios.post(`${process.env.VUE_APP_ROOT_API}/payment-methods-v2/create-setup-intent`)
            .then(response => {
              this.stripeSetupIntent = response.data.data.intent
              resolve(this.stripeSetupIntent)
            })
            .catch(err => {
              reject(err)
            })
        }
      })
    },
    async includeStripe () {
      return new Promise((resolve, reject) => {
        if (!document.getElementById('stripe_script')) {
          const documentTag = document
          const tag = 'script'
          const object = documentTag.createElement(tag)
          const scriptTag = documentTag.getElementsByTagName(tag)[0]

          object.id = 'stripe_script'
          object.src = 'https://js.stripe.com/v3/'
          object.addEventListener('load', (e) => {
            resolve(true)
          }, false)

          scriptTag.parentNode.insertBefore(object, scriptTag)
        } else {
          resolve(true)
        }
      })
    },
    initCardElement () {
      /* global Stripe */
      /* eslint no-undef: "error" */

      if (!this.stripe.stripe || !this.stripe.elements || !this.stripe.cardElement) {
        this.stripe.stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY)
        this.stripe.elements = this.stripe.stripe.elements()
        this.stripe.cardElement = this.stripe.elements.create('card')
      }
      process.nextTick(() => {
        if (document.getElementById('card-element1')) {
          this.stripe.cardElement.mount('#card-element1')
        } else if (document.getElementById('card-element2')) {
          this.stripe.cardElement.mount('#card-element2')
        }
      })
    }
  },
  watch: {
    'stripe.cardElement': {
      deep: true,
      handler () {
        this.stripeCardElementExtraClass = ''
        this.stripeValidationError = ''
      }
    }
  }
}
