<template>
  <b-container class="component-settings-subscription-wrapper bg-light page-wrapper">
    <b-row class="main-content-wrapper pb-0" no-gutters>
      <b-col>
        <settings-submenu></settings-submenu>
      </b-col>
    </b-row>
    <b-row class="main-content-wrapper pt-0" no-gutters>
      <b-col sm="12" md="5" lg="3" class="detail-text text-left py-4 pr-5">
        <template v-if="activeTab === 0">
          <h3 class="text-dark">{{ $t(translationPath + 'subscription_prices.left_sidebar.title') }}</h3>
          <p class="text-gray">{{ $t(translationPath + 'subscription_prices.left_sidebar.description') }}</p>
        </template>
        <template v-else-if="activeTab === 1">
          <h3 class="text-dark">{{ $t(translationPath + 'invoices.left_sidebar.title') }}</h3>
          <p class="text-gray">{{ $t(translationPath + 'invoices.left_sidebar.description') }}</p>
        </template>
        <template v-else>
          <h3 class="text-dark">{{ $t(translationPath + 'payment_methods.left_sidebar.title') }}</h3>
          <p class="text-gray">{{ $t(translationPath + 'payment_methods.left_sidebar.description') }}</p>
        </template>
      </b-col>
      <b-col class="py-2 px-0">
        <b-tabs v-model="activeTab" card class="transparent-tabs">
          <b-tab :title="$t(translationPath + 'tab_subscription')" @click="changeTabRoute('plans')" class="pt-3">
            <subscription-prices @subscription-status="onSubscriptionStatusReceive" />
          </b-tab>
          <b-tab :title="$t(translationPath + 'tab_invoices')" @click="changeTabRoute('invoices')" class="pt-3">
            <subscription-invoices />
          </b-tab>
          <b-tab :title="$t(translationPath + 'tab_cards')" @click="changeTabRoute('payment-methods')" class="pt-3">
            <subscription-payment-methods :subscription-status="subscriptionStatus" />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import SettingsSubmenu from '../SettingsSubmenu'
import TitleMixins from '@/mixins/title'
import SubscriptionInvoices from './SubscriptionInvoices'
import SubscriptionPaymentMethods from './SubscriptionPaymentMethods'
import SubscriptionPrices from '@/views/settings/subscription-v2/SubscriptionPrices'

export default {
  name: 'YourDetails',
  components: { SubscriptionPrices, SubscriptionPaymentMethods, SettingsSubmenu, SubscriptionInvoices },
  mixins: [TitleMixins],
  data () {
    return {
      validRouteParams: ['plans', 'invoices', 'payment-methods'],
      translationPath: 'settings.subscription-v2.',
      activeTab: 0,
      subscriptionStatus: null
    }
  },
  computed: {
    ...mapState('user', ['currentUser']),
    titleMeta () {
      return this.$t('settings.submenu.your_details')
    }
  },
  methods: {
    changeTabRoute (tab) {
      if (tab !== this.$route.params.tab) {
        this.$router.push({ params: { tab: tab } })
      }
    },
    onSubscriptionStatusReceive (status) {
      this.subscriptionStatus = status
    }
  },
  created () {
    if (this.validRouteParams.indexOf(this.$route.params.tab) !== -1) {
      switch (this.$route.params.tab) {
        case 'plans':
          this.activeTab = 0
          break
        case 'invoices':
          this.activeTab = 1
          break
        case 'payment-methods':
          this.activeTab = 2
          break
      }
    } else {
      this.$router.push({ params: { tab: 'plans' } })
    }
  },
  watch: {
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.component-settings-subscription-wrapper{
  .detail-text{
    border-right: 1px solid #D7D7D7;
  }
}
</style>
