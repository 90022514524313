<template>
  <div class="component-settings-subscription-invoices px-4">
    <b-row>
      <b-col cols="col-md-8">
        <h3 class="pt-4">{{$t(translationPath + 'title')}}</h3>
        <div v-html="$t(translationPath + 'description')"></div>

        <b-table class="spirecta-simple-table settings-invoice-table mb-0" show-empty hover responsive striped
                 :items="aTableInvoiceRows"
                 :fields="tableColumns"
                 :busy="busyState"
                 :emptyText="$t(translationPath + 'no_data')"
        >
          <template v-slot:table-busy>
            <loader/>
          </template>

          <!-- SLOT: created_at -->
          <template v-slot:cell(created_at)="row">
            <span class="text-nowrap">{{row.value}}</span>
          </template>

          <!-- SLOT: period -->
          <template v-slot:cell(period)="row">
            <template v-if="row.item.period_months === 1">
              {{$t(translationPath + 'subscription_period_1month',{period_start:row.item.period_start, period_end: row.item.period_end})}}
            </template>
            <template v-else>
              {{$t(translationPath + 'subscription_period_many_months',{period_months: row.item.period_months, period_start:row.item.period_start, period_end: row.item.period_end})}}
            </template>
          </template>

          <!-- SLOT: invoice number -->
          <template v-slot:cell(invoice_number)="row">
            <span class="text-nowrap"><b-link :href="row.item.hosted_invoice_url" target="_blank">{{row.value}}</b-link></span>
          </template>

          <!-- SLOT: amount -->
          <template v-slot:cell(amount)="row">
            <span class="text-nowrap">{{row.value}} {{row.item.currency}}</span>
          </template>

          <!-- SLOT: paid -->
          <template v-slot:cell(paid)="row">
            {{$t(row.value === 1 ? 'common.yes' : 'common.no')}}
          </template>

          <!-- SLOT: hosted_invoice_url -->
          <template v-slot:cell(hosted_invoice_url)="row">
            <template v-if="row.item.paid === 1">
              <b-link :href="row.item.hosted_invoice_url" target="_blank" class="text-red">{{$t(translationPath + 'receipt')}}</b-link>
              (<b-link :href="row.item.invoice_pdf" target="_blank" class="text-red">{{$t(translationPath + 'receipt_pdf')}}</b-link>)
            </template>
            <template v-else>
              <b-link :href="row.item.hosted_invoice_url" target="_blank" class="text-red">{{$t(translationPath + 'invoice_link')}}</b-link>
            </template>
          </template>

        </b-table>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import Loader from '@/components/common/Loader'

export default {
  name: 'SubscriptionInvoices',
  components: { Loader },
  data () {
    return {
      translationPath: 'settings.subscription-v2.invoices.',
      busyState: false,
      aTableInvoiceRows: []
    }
  },
  computed: {
    tableColumns () {
      return [
        { key: 'created_at', label: this.$t(this.translationPath + 'td_created_at'), class: 'td-date', sortable: true, formatter: (value) => moment(value).format('YYYY-MM-DD') },
        { key: 'period', label: this.$t(this.translationPath + 'td_period'), class: 'td-period' },
        { key: 'invoice_number', label: this.$t(this.translationPath + 'td_invoice_number'), class: 'td-invoice_number' },
        { key: 'amount', label: this.$t(this.translationPath + 'td_amount'), class: 'td-amount text-right' },
        { key: 'paid', label: this.$t(this.translationPath + 'td_paid'), class: 'td-amount' },
        { key: 'hosted_invoice_url', label: this.$t(this.translationPath + 'td_payment_receipt'), class: 'td-receipt text-right' }
      ]
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    async loadData () {
      return new Promise((resolve, reject) => {
        this.busyState = true

        axios.get(`${process.env.VUE_APP_ROOT_API}/subscriptions/invoices`)
          .then(response => {
            this.apiData = response.data.data

            for (const idx in this.apiData) {
              const oTmp = {}
              oTmp.created_at = this.apiData[idx].stripe_created_at
              oTmp.hosted_invoice_url = this.apiData[idx].hosted_invoice_url
              oTmp.invoice_pdf = this.apiData[idx].invoice_pdf
              oTmp.period_start = moment(this.apiData[idx].spirecta_period_start).format('YYYY-MM-DD')
              oTmp.period_end = moment(this.apiData[idx].spirecta_period_end).format('YYYY-MM-DD')
              oTmp.period_months = this.apiData[idx].months
              oTmp.invoice_number = this.apiData[idx].invoice_number
              oTmp.amount = this.apiData[idx].total
              oTmp.currency = this.apiData[idx].currency
              oTmp.paid = this.apiData[idx].paid

              this.aTableInvoiceRows.push(oTmp)
            }

            this.busyState = false
            resolve(response.data.data)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
      })
    }
  }
}
</script>

<style lang="scss">
.component-settings-subscription-invoices{
  .settings-invoice-table{
    td, th{
      font-size: 90%;
    }
    td a.text-red{
      color: #ca807c;
    }
  }
}
</style>
